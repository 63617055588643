<template>
  <section class="container">
    <p class="title">Add Weekly Challenge</p>
    <hr class="separator" />
    <!--Add Weekly-->
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="subtitle">Description</p>
        <hr />
        <div class="row">
          <div class="col-12">
            <textarea
              type="text"
              v-model="weekly.description"
              class="shadow-sm"
              placeholder="Description"
            ></textarea
            ><br />
            <select v-model="weekly.difficulty" class="shadow-sm">
              <option value="">Difficulty</option>
              <option value="1">Easy</option>
              <option value="2">Medium</option>
              <option value="3">Hard</option>
            </select>
          </div>
        </div>
        <br /><br />
        <p class="subtitle">Objectives</p>
        <hr />
        <div
          class="row m-0"
          v-for="(input, index) in weekly.objectives"
          :key="index"
        >
          <div class="col-9 col-md-10 p-0">
            <input
              type="number"
              class="input-group-alternative mb-3 shadow-sm"
              v-model="weekly.objectives[index].target"
              placeholder="Target"
            />
            <select
              v-model="weekly.objectives[index].objective"
              class="shadow-sm"
            >
              <option value="">Objective type</option>
              <option
                v-for="(input, index) in Objectives"
                :key="index"
                :value="Objectives[index]"
              >
                {{ ObjectivesDesc[index] }}
              </option>
            </select>
            <hr />
          </div>
          <div class="col-3 col-md-2 p-0" style="margin-top: 10px">
            <button v-on:click="DeleteObjective(index)" class="btn-sm">
              <img src="../assets/img/remove.png" />
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button v-on:click="AddObjective" class="btn-sm float-left">
              Add objective &nbsp;&nbsp;&nbsp;<img
                src="../assets/img/add.png"
              />
            </button>
          </div>
        </div>

        <br /><br />
        <p class="subtitle">Rewards</p>
        <hr />
        <div
          class="row m-0"
          v-for="(input, index) in weekly.rewards"
          :key="index"
        >
          <div class="col-9 col-md-10 p-0">
            <input
              type="number"
              class="input-group-alternative mb-3 shadow-sm"
              v-model.trim="weekly.rewards[index].amount"
              placeholder="Amount"
            />
            <select
              v-model="weekly.rewards[index].rewardType"
              class="shadow-sm"
            >
              <option value="">Reward type</option>
              <option
                v-for="(input, index) in Rewards"
                :key="index"
                :value="Rewards[index]"
              >
                {{ RewardsDesc[index] }}
              </option>
            </select>
            <hr />
          </div>
          <div
            class="col-3 col-md-2 pl-1 pr-1 pt-0 pb-0"
            style="margin-top: 10px"
          >
            <button v-on:click="DeleteReward(index)" class="btn-sm">
              <img src="../assets/img/remove.png" />
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button v-on:click="AddReward" class="btn-sm float-left">
              Add reward &nbsp;&nbsp;&nbsp;<img src="../assets/img/add.png" />
            </button>
          </div>
        </div>
        <br />
        <p v-if="success" class="alert-success">Daily added successfuly</p>
        <p v-if="error != ''" class="alert-error">{{ error }}</p>
        <br />
        <button v-if="!isEditing" v-on:click.prevent="AddWeekly">
          Add weekly &nbsp;&nbsp;&nbsp;<img src="../assets/img/add.png" />
        </button>
        <button v-if="isEditing" v-on:click.prevent="UpdateWeekly">
          Update weekly &nbsp;&nbsp;&nbsp;<img src="../assets/img/edit.png" />
        </button>
        <button v-if="isEditing" v-on:click.prevent="CancelEdit">Cancel</button>
      </div>
      <!--Weekly missions-->
      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-12 mb-2 mt-2">Production weekly challenges</div>
        </div>
        <hr style="margin: 3px auto" />
        <div
          class="row"
          v-for="(weekly, index) in weeklyChallengesProduction"
          :key="index"
        >
          <div class="col-12">
            <button
              class="collapse-button"
              type="button"
              @click.stop="ToggleWeekly(index)"
            >
              <div class="row ml-0 mr-0">
                <div class="col-2">
                  <img src="../assets/img/task.png" />
                </div>
                <div class="col-8" style="text-align: left">
                  {{ weekly.description[0] }}
                </div>
                <div class="col-2">
                  <img
                    v-if="weekly.show"
                    src="../assets/img/dropdown_open.png"
                  />
                  <img
                    v-if="!weekly.show"
                    src="../assets/img/dropdown_close.png"
                  />
                </div>
              </div>
            </button>

            <div
              class="collapse"
              v-bind:class="{ show: weekly.show }"
              style="width: 100%; text-align: left; padding: 5px"
            >
              {{ weekly.description[0] }}<br /><br />
              <span class="subtitle-secondary">Difficulty:</span>
              {{ weekly.difficulty }}
              <hr />
              Objectives<br /><br />
              <div
                v-for="objective in weeklyChallengesProduction[index]
                  .objectives"
                :key="objective._id"
              >
                <span class="subtitle-secondary">Target:</span>
                {{ objective.target }}<br />
                <span class="subtitle-secondary">Objective:</span>
                {{ ObjectivesDesc[objective.objective] }}
              </div>
              <hr />
              Rewards<br /><br />
              <div
                v-for="reward in weeklyChallengesProduction[index].rewards"
                :key="reward._id"
              >
                <span class="subtitle-secondary">Amount:</span>
                {{ reward.amount }}<br />
                <span class="subtitle-secondary">Reward type:</span>
                {{ RewardsDesc[reward.rewardType] }}
              </div>
              <br />
              <button
                class="btn-sm"
                v-on:click="
                  SetWeekly(
                    weekly._id,
                    weekly.description,
                    weekly.difficulty,
                    weekly.objectives,
                    weekly.rewards
                  )
                "
              >
                <img src="../assets/img/edit.png" />
              </button>
            </div>
            <hr style="margin: 3px auto" />
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-2 mt-2">Test weekly challenges</div>
        </div>
        <hr style="margin: 3px auto" />
        <div
          class="row"
          v-for="(weekly, index) in weeklyChallengesTest"
          :key="index"
        >
          <div class="col-12">
            <button
              class="collapse-button"
              type="button"
              @click.stop="ToggleWeekly(index)"
            >
              <div class="row ml-0 mr-0">
                <div class="col-2">
                  <img src="../assets/img/task.png" />
                </div>
                <div class="col-8" style="text-align: left">
                  {{ weekly.description[0] }}
                </div>
                <div class="col-2">
                  <img
                    v-if="weekly.show"
                    src="../assets/img/dropdown_open.png"
                  />
                  <img
                    v-if="!weekly.show"
                    src="../assets/img/dropdown_close.png"
                  />
                </div>
              </div>
            </button>

            <div
              class="collapse"
              v-bind:class="{ show: weekly.show }"
              style="width: 100%; text-align: left; padding: 5px"
            >
              {{ weekly.description[0] }}<br /><br />
              <span class="subtitle-secondary">Difficulty:</span>
              {{ weekly.difficulty }}
              <hr />
              Objectives<br /><br />
              <div
                v-for="objective in weeklyChallengesTest[index]
                  .objectives"
                :key="objective._id"
              >
                <span class="subtitle-secondary">Target:</span>
                {{ objective.target }}<br />
                <span class="subtitle-secondary">Objective:</span>
                {{ ObjectivesDesc[objective.objective] }}
              </div>
              <hr />
              Rewards<br /><br />
              <div
                v-for="reward in weeklyChallengesTest[index].rewards"
                :key="reward._id"
              >
                <span class="subtitle-secondary">Amount:</span>
                {{ reward.amount }}<br />
                <span class="subtitle-secondary">Reward type:</span>
                {{ RewardsDesc[reward.rewardType] }}
              </div>
              <br />
              <button
                class="btn-sm"
                v-on:click="
                  SetWeekly(
                    weekly._id,
                    weekly.description,
                    weekly.difficulty,
                    weekly.objectives,
                    weekly.rewards
                  )
                "
              >
                <img src="../assets/img/edit.png" />
              </button>
            </div>
            <hr style="margin: 3px auto" />
          </div>
        </div>
      </div>
    </div>
    <br />
  </section>
</template>
<script>
import WeeklyService from "@/services/WeeklyService.js";
import {
  Objectives,
  ObjectivesDesc,
} from "@/resources/enumerables/ObjectiveType";
import { Rewards, RewardsDesc } from "@/resources/enumerables/RewardType";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      Objectives,
      ObjectivesDesc,
      Rewards,
      RewardsDesc,
      weekly: {
        _id: "",
        description: "",
        difficulty: "",
        objectives: [{ target: "", objective: "" }],
        rewards: [{ rewardType: "", amount: "" }],
      },
      weeklyChallengesProduction: [],
      weeklyChallengesTest: [],
      isEditing: false,
      error: "",
      success: false,
    };
  },
  validations: {
    weekly: { required },
  },
  mounted() {
    this.GetWeeklyProduction();
    this.GetWeeklyTest();
  },
  methods: {
    GetWeeklyProduction() {
      return WeeklyService.GetWeekly("Production")
        .then(async ({ data }) => {
          this.weeklyChallengesProduction = data.response;
          this.weeklyChallengesProduction.forEach((element) => (element.show = false));
          console.log(data);
        })
        .catch((e) => {
          console.log(e);
          window.location.href = "/error";
        });
    },
    GetWeeklyTest() {
      return WeeklyService.GetWeekly("Test")
        .then(async ({ data }) => {
          this.weeklyChallengesTest = data.response;
          this.weeklyChallengesTest.forEach((element) => (element.show = false));
          console.log(data);
        })
        .catch((e) => {
          console.log(e);
          window.location.href = "/error";
        });
    },
    AddObjective() {
      this.weekly.objectives.push({ target: "", objective: "" });
    },
    DeleteObjective(objectiveIndex) {
      this.weekly.objectives.splice(objectiveIndex, 1);
    },
    AddReward() {
      this.weekly.rewards.push({ rewardType: "", amount: "" });
    },
    DeleteReward(rewardIndex) {
      this.weekly.rewards.splice(rewardIndex, 1);
    },
    async AddWeekly() {
      this.success = false;
      if (!this.CheckForm()) return;
      let data = {
        description: this.weekly.description,
        difficulty: this.weekly.difficulty,
        objectives: this.weekly.objectives,
        rewards: this.weekly.rewards,
      };

      return WeeklyService.AddWeekly(data)
        .then(async ({ data }) => {
          console.log(data);
          this.Clear();
          this.GetWeekly();
          this.success = true;
        })
        .catch((e) => {
          window.location.href = "/error";
        });
    },
    async UpdateWeekly() {
      return WeeklyService.UpdateWeekly(this.weekly)
        .then(async ({ data }) => {
          console.log(data);
          this.CancelEdit();
          this.GetWeekly();
        })
        .catch((e) => {
          window.location.href = "/error";
        });
    },
    async DeleteWeekly(id) {
      return WeeklyService.DeleteWeekly(id)
        .then(async ({ data }) => {
          console.log(data);
          this.Clear();
          this.GetWeekly();
        })
        .catch((e) => {
          window.location.href = "/error";
        });
    },
    SetWeekly(_id, description, difficulty, objectives, rewards) {
      this.weekly._id = _id;
      this.weekly.description = description;
      this.weekly.difficulty = difficulty;
      this.weekly.objectives = objectives;
      this.weekly.rewards = rewards;
      this.isEditing = true;
    },
    CancelEdit() {
      this.isEditing = false;
      this.Clear();
    },
    Clear() {
      this.weekly = {
        description: "",
        difficulty: "",
        objectives: [{ target: "", objective: "" }],
        rewards: [{ rewardType: "", amount: "" }],
      };
    },
    CheckForm() {
      this.error = "";

      if (!this.weekly.description || !this.weekly.difficulty)
        this.error = "Invalid or missing params";

      this.weekly.objectives.forEach((element) => {
        if (!element.objective || !element.target)
          this.error = "Invalid or missing params";
      });

      this.weekly.rewards.forEach((element) => {
        if (!element.rewardType || !element.amount)
          this.error = "Invalid or missing params";
      });

      if (!this.error) return true;
      else return false;
    },
    ToggleWeekly(index) {
      this.weeklyChallenges[index].show = !this.weeklyChallenges[index].show;
    },
  },
};
</script>
<style scoped>
@import "../assets/css/Daily.css";
</style>